import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useParams } from "react-router-dom";
import { checkLocation } from "../../../helpers/utiles";
import { campaignClient, setSessionClient } from "../../../api";

declare const window: Window &
    typeof globalThis & {
        vex: any;
    };

export default function Landing() {
    const { t, i18n } = useTranslation();
    const isEnglish = i18n.language === "en";
    const navigate = useNavigate();
    const { location } = useParams();

    useEffect(() => {
        if (location && checkLocation(location)) {
            window.vex.dialog.alert({
                unsafeMessage: t("splash.location"),
                callback: () => {
                    navigate("/");
                    return;
                },
            });
        }else{
            setSessionClient(campaignClient.createSession())
        }
    }, [location]);

    return (
        <div className="main">
            <section className="section-secondary">
                <div className="shell">
                    <div className="section__inner">
                        <div className="section__content">
                            <div className="cta">
                                <figure>
                                    <img className="logoWhite" src="/assets/images/svg/logoWhite.svg" alt="" width="450" />
                                </figure>

                                <figure className={isEnglish ? "" : "imagePadding"}>
                                    <img src={isEnglish ? "/assets/images/temp/levelUpPlump.png" : "/assets/images/temp/levelUpPlumpFR.png"} alt="" width="700" />
                                </figure>

                                <div className="cta__entry">
                                    <p> {t("splash.3")}</p>
                                </div>
                                {/* /.cta__entry */}

                                <Link to={`/game/${location}`} className="btnCustom">
                                    {t("splash.7")}
                                </Link>
                            </div>
                            {/* /.cta */}
                        </div>
                        {/* /.section__content */}
                    </div>
                    {/* /.section__inner */}
                </div>
                {/* /.shell */}
            </section>
            {/* /.section-secondary */}
        </div>
    );
}
