import { useTranslation } from "react-i18next";
import useRequiredCampaignState from "../../hooks/useRequiredCampaignState";
import Header from "../../Components/Header";

export default function Closed() {
    useRequiredCampaignState("closed");
    const { t } = useTranslation();
    return (
        <div className="wrapper">
            <Header />
            <div className="main">
                <section className="section-secondary">
                    <div className="shell">
                        <div className="section__inner">
                            <div className="section__content">
                                <div className="cta">
                                    <figure className="">
                                        <img src="/assets/images/svg/logoWhite.svg" alt="" width="450" />
                                    </figure>

                                    <div className="cta__entry imagePadding">
                                        <p> {t("closed.1")}</p>
                                    </div>
                                    {/* /.cta__entry */}
                                </div>
                                {/* /.cta */}
                            </div>
                            {/* /.section__content */}
                        </div>
                        {/* /.section__inner */}
                    </div>
                    {/* /.shell */}
                </section>
                {/* /.section-secondary */}
            </div>
        </div>
    );
}
