import { Outlet } from "react-router-dom";
import useRequiredCampaignState from "../../hooks/useRequiredCampaignState";
import Header from "../../Components/Header";

export default function Open() {
    useRequiredCampaignState("open");

    return (
        <div className="wrapper">
            <Header />
            <Outlet />
        </div>
    );
}
