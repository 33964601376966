import { FunctionComponent, useEffect, useLayoutEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import card from "./images/card.png";
import card_fr from "./images/card_fr.png";

import heard from "./images/heard.png";
import lips from "./images/lips.png";
import heard_fr from "./images/heard_fr.png";

import FlipImage from "../../../Components/FlipImage/index.js";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { campaignClient, getSessionClient, setSessionClient } from "../../../api";
import i18n from "../../../i18n";
import { checkLocation } from "../../../helpers/utiles";

interface GameProps {}
declare const window: Window &
    typeof globalThis & {
        vex: any;
    };

const Game: FunctionComponent<GameProps> = () => {
    const { t, i18n } = useTranslation();

    const isEnglish = i18n.language === "en";

    const navigate = useNavigate();

    const [isWin, setIsWin] = useState("");

    const [imgIndex, setImgIndex] = useState({ index: 0, clicks: 0});

    const { location } = useParams();


    useEffect(() => {
        if (location && checkLocation(location)) {
            window.vex.dialog.alert({
                unsafeMessage: t("splash.location"),
                callback: () => {
                    navigate("/");
                    return;
                },
            });
        }
    }, [location]);

    useEffect(() => {
        if (imgIndex.clicks >= 2) {
            setTimeout(function () {
                navigate(`/thankYou/${isWin === "yes" ? "winner" : "no"}/${location}`);
            }, 3000);
            return;
        }
    }, [imgIndex.clicks]);
    // console.log(cardArray, isWin);

    useLayoutEffect(() => {
        !isWin && handleRegister();       
    }, []);

    async function handleRegister() {
        try {
            const { win } = await campaignClient.call<{ win: boolean }>("register", {
                sessionKey: campaignClient.recoverSession().sessionKey,
                location,
                language: i18n.language,
            });
            setIsWin(win === true ? "yes" : "no");
        } catch (e) {
            console.log(e);
        }
    }

    return (
        <div className="main">
            <section className="section-secondary">
                <div className="shell">
                    <div className="section__inner">
                        <div className="section__content">
                            <div className="cta">
                                <figure className="">
                                    <img src="/assets/images/svg/logoWhite.svg" alt="" width="450" />
                                </figure>

                                <div className="gameTitle">
                                    <p> {t("game.1")}</p>
                                </div>

                                {isWin && (
                                    <FlipImage
                                        //img1={img1}
                                        //setImg1={setImg1}
                                        //setIsWin={setIsWin}
                                        //img2={img2}
                                        //setImg2={setImg2}
                                        //randomArr={cardArray}
                                        FrontImg={isEnglish ? card : card_fr}
                                        isWin={isWin}
                                        onClick = { (index:number)=>{ 
                                        setImgIndex({
                                           index: index + 1,
                                           clicks: imgIndex.clicks + 1
                                        })
                                        } }
                                        imgIndex={imgIndex}
                                        lipsOrHeard = {Math.random() > 0.5 ? "lips" : "heard"}
                                        isEnglish= {isEnglish}
                                    />
                                )}

                                <div className="cta__entry">
                                    {imgIndex.clicks == 0 && <p> {t("game.2")}</p>}
                                    {imgIndex.clicks == 1 && <p> {t("game.3")}</p>}
                                    {imgIndex.clicks >=2 && isWin === "yes" && <p> {t("game.4")}</p>}
                                    {imgIndex.clicks >=2 && isWin === "no" && <p> {t("game.5")}</p>}
                                </div>
                            </div>
                            {/* /.cta */}
                        </div>
                        {/* /.section__content */}
                    </div>
                    {/* /.section__inner */}
                </div>
                {/* /.shell */}
            </section>
            {/* /.section-secondary */}
        </div>
    );
};

export default Game;
