import { useTranslation } from "react-i18next";
import disableBrowserHistory from "../../../Components/DisableBrowserBack";
import { Link, useParams } from "react-router-dom";

export default function ThankYou() {
    disableBrowserHistory();
    const { t, i18n } = useTranslation();
    const isEnglish = i18n.language === "en";
    const { win, location } = useParams();
    const isWinner = win === "winner";

    return (
        <div className="main">
            <section className="section-primary">
                <div className="shell">
                    <div className="section__inner">
                        <div className="section__content">
                            <figure className="">
                                <img className="logoWhite" src="/assets/images/svg/logoWhite.svg" alt="" width="300" />
                            </figure>

                            <figure className={isEnglish ? "" : "imagePadding"}>
                                <img src={isEnglish ? "/assets/images/temp/levelUpPlump.png" : "/assets/images/temp/levelUpPlumpFR.png"} alt="" width="700" />
                            </figure>
                            <figure>
                                <img
                                    className="logoLip"
                                    src={!isWinner ? "/assets/images/temp/lip.png" : isEnglish ? "/assets/images/temp/heardEN.png" : "/assets/images/temp/heardFR.png"}
                                    alt=""
                                    width="200"
                                />
                            </figure>
                            <div className="cta__title">
                                <h3> {isWinner ? t("thankYou.win") : t("thankYou.thanks")}</h3>
                            </div>
                            <div className="cta__title2">
                                {" "}
                                {isWinner ? <p>{t("thankYou.1")} </p> : <p> {t("thankYou.text")} </p>}
                                <p> {t("thankYou.text2")} </p>
                            </div>
                            {/* /.cta__entry */}

                            <Link to={`/${location}`} className="btnCustom">
                                {t("thankYou.done")}
                            </Link>
                        </div>
                    </div>
                    {/* /.section__inner */}
                </div>
                {/* /.shell */}
            </section>
            {/* /.section-primary */}
        </div>
    );
}
