import React, { useEffect, useImperativeHandle, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "./styles.css";
import { defaultStorageHelper } from "@spry/campaign-client";

const Flippy = React.forwardRef(
    (
        {           
           
            index,           
            imgIndex,
            value,
            className,
            flipDirection,
            style,           
            children,
            flipOnHover,
            flipOnClick,
            onClick,
            onTouchStart,
            onMouseEnter,
            onMouseLeave,
            ...rest
        },
        ref
    ) => {
        const [isFlipped, setFlipped] = useState(false);

       // const toggle = () => setFlipped(!isFlipped);

       // useImperativeHandle(ref, () => ({ toggle }));    

        const handleClick = (event) => {

            if (imgIndex.clicks >= 2){             
                return;               
            }
            
            if (isFlipped) {               
                return;
            } 
            onClick(index);
            setTimeout(()=>{
                setFlipped(true);
            }, 200)


               // if (!img1){
                    //   setImg1(value);
                    // setImgIndex({
                    //     index: index + 1,

                    // });   
                                   // }
                // if (!img1) {
                //     setImg1(value);
                //     setImgIndex(index);
                //     setFlipped(true);
                // } else if (!img2 && img1) {
                //     setImg2(value);

                //     setTimeout(function () {
                //         setFlipped(true);
                //     }, 500);
                //     setTimeout(function () {
                //         navigate(`/thankYou/${isWin === "yes" ? "winner" : "no"}/${location}`);
                //     }, 5000);
                // } }
            
        };

        return (
            <div
                {...rest}
                className={`flippy-container ${className || ""}`}
                style={{
                    ...style,
                }}
                onClick={handleClick}>
                <div className={`flippy-cardContainer-wrapper ${flipDirection}`}>
                    <div className={`flippy-cardContainer ${isFlipped ? "isActive" : ""}`}>{children}</div>
                </div>
            </div>
        );
    }
);

export default Flippy;

Flippy.defaultProps = {
    flipDirection: "horizontal",
    flipOnHover: false,
    flipOnClick: true,

    onMouseEnter: () => {},
    onMouseLeave: () => {},
    onTouchStart: () => {},
    onClick: () => {},
};
