import { Route } from "react-router-dom";

import "../i18n";
import Game from "../Pages/Open/Game";
import ThankYou from "../Pages/Open/ThankYou";
import Open from "../Pages/Open";
import Landing from "../Pages/Open/Landing";
import Closed from "../Pages/Closed";
import ComingSoon from "../Pages/ComingSoon";

export default (
    <>
        <Route path="/" element={<Open />}>
            <Route path=":location" element={<Landing />} />
            <Route path="/game/:location" element={<Game />} />
            {/* <Route path="/thankYou/:location" element={<ThankYou />} /> */}
            <Route path="/thankYou/:win/:location" element={<ThankYou />} />
        </Route>

        <Route path="closed" element={<Closed />} />
        <Route path="coming" element={<ComingSoon />} />
    </>
);
