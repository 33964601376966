import { defaultStorageHelper } from "@spry/campaign-client";
import { FunctionComponent, useEffect } from "react";
import { useTranslation } from "react-i18next";

interface HeaderProps {}

const Header: FunctionComponent<HeaderProps> = () => {
    const { t, i18n } = useTranslation();

    useEffect(() => {
        document.title = t("splash.browser");
    }, [i18n.language]);

    const switchLanguage = () => {
        const newLanguage = i18n.language === "en" ? "fr" : "en";
        defaultStorageHelper.set("lang", newLanguage);
        i18n.changeLanguage(newLanguage);
    };
    return (
        <>
            <figure className="wrapper__background">
                <img src="/assets/images/temp/background.png" alt="" width="1920" height="1050" />
            </figure>
            <figure className="wrapper__background_mobile">
                <img src="/assets/images/temp/bg_mobile.png" alt=""  />
            </figure>
            <div className="languageToggle" onClick={switchLanguage} style={{ cursor: "pointer" }}>
                {t("splash.header")}
            </div>
        </>
    );
};

export default Header;
