import { FunctionComponent, useEffect, useLayoutEffect, useRef, useState } from "react";
import Flippy, { BackSide, FrontSide } from "./lib/index.js";
import card from "./images/card.png";
import card_fr from "./images/card_fr.png";
import heard from "./images/heard.png";
import lips from "./images/lips.png";
import heard_fr from "./images/heard_fr.png";

const FlippyOnClick = ({ flipDirection = "vertical", BackImage, FrontImg, value, onClick, index, imgIndex }) => {
    return (
        <Flippy
        index={index}          
            onClick={onClick}
            value={value}                
            imgIndex={imgIndex}
            // flipOnClick={false}
            flipDirection={flipDirection}>
            <FrontSide>
                <div className="cards__item">
                    <figure className="card__image">
                        <img src={FrontImg} alt="" />
                    </figure>
                </div>
            </FrontSide>
            <BackSide>
                <div className="cards__item">
                    <figure className="card__image">
                        <img src={BackImage} alt="" />
                    </figure>
                </div>
            </BackSide>
        </Flippy>
    );
};

const FlipImage = ({ FrontImg, isWin, lipsOrHeard, isEnglish, imgIndex, onClick }) => {

    const cardLips = { value: "lips", img: lips };
    const cardHeard = { value: "heard", img: isEnglish ? heard : heard_fr };

    const flipInitial = useRef(lipsOrHeard);

    const arrayLips = [cardLips, cardHeard, cardLips];
    const arrayHeard = [cardHeard, cardLips, cardHeard];

    const [cardArray, setCardArray] = useState(flipInitial === "lips" ? arrayLips : arrayHeard);

    useEffect(()=>{
        //if not win and index is not 2(middle)
        if (isWin !== "yes" && imgIndex.index > 0 && imgIndex.index !== 2 && imgIndex.clicks<=1){

            const newArray = [...cardArray];
          
            newArray[imgIndex.index === 1 ? 2 : 0] = flipInitial === "lips" ? cardHeard : cardLips;  
           
            setCardArray(newArray)
        }
         //if win and click middle image
         if (isWin === "yes" && imgIndex.index > 0 && imgIndex.clicks<=1){          
            const newArray = cardArray[imgIndex.index - 1].value === "lips" ?  [cardLips, cardLips, cardLips] : [cardHeard, cardHeard, cardHeard];
            setCardArray([...newArray])
         }

    }, [imgIndex])


    return (
        <div className="FlipImage">
            <div className="section__body">
                <div className="cards">
                    <div className="cards__items">
                        {cardArray.map((item, index) => (
                            <FlippyOnClick                                                              
                                imgIndex={imgIndex}                              
                                key={index}
                                index={index}
                                flipDirection="horizontal"
                                value={item.value}
                                BackImage={item.img}
                                FrontImg={FrontImg}                            
                                onClick={onClick}
                            />
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FlipImage;
